import React from 'react';
import { css } from '@emotion/core';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import website from '../images/website.png';
import call_rep from '../images/step1_rep.jpg';
import docupop_logo from '../images/docupop_logo.png';

const contentWrapCSS = css`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  text-align: center;

  h1 {
    margin-top: 60px;
    font-size: 62px;
  }

  h2 {
    margin-bottom: 0;
    font-weight: 500;
    color: #517bfe;
    font-size: 24px;
  }

  a {
    text-decoration: none;
  }
`;

const imageChartCSS = css`
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 20px;

  @media only screen and (max-width: 480px) {
    display: block;

    .column {
      margin: auto;
      margin-bottom: 30px;
    }

    h4 {
      margin-bottom: 35px;
    }
  }

  .column {
    padding: 20px;
    width: 275px;

    img {
      width: 125px;
      margin-bottom: 15px;
    }

    h4 {
      color: #517bfe;
      margin-bottom: 5px;
    }
  }
`;

const bugsnagClient = bugsnag('280b2412a99d2fa229ca2a8eb91dbecf');
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');

const ThankYouPage = () => (
  <ErrorBoundary>
    <div className="bg-blue-700 h-2" />
    <div
      className="bg-blue-300 pt-6 font-sans"
      css={css`
        background: #7abcff; /* Old browsers */
        background: -moz-radial-gradient(
          center,
          ellipse cover,
          #7abcff 0%,
          #60abf8 44%,
          #4096ee 100%
        ); /* FF3.6-15 */
        background: -webkit-radial-gradient(
          center,
          ellipse cover,
          #7abcff 0%,
          #60abf8 44%,
          #4096ee 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: radial-gradient(
          ellipse at center,
          #7abcff 0%,
          #60abf8 44%,
          #4096ee 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7abcff', endColorstr='#4096ee',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      `}
    >
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="flex mb-4">
            <div className="w-1/2">
              <img
                src={docupop_logo}
                style={{ width: '200px', margin: 'auto' }}
                alt="docupop logo"
              />
            </div>
            {/* <div className="w-1/2 text-right text-white font-sans">
              <p>Need Help Now? Call Us!</p>
              <p className="-mt-5">
                <a
                  href="tel:+18559106986"
                  className="text-gray-800 text-xl font-bold no-underline"
                >
                  (855) 910-6986
                </a>
              </p>
            </div> */}
          </div>
          <div className="mb-4 mx-auto text-center pt-24 pb-24">
            <h1 className="text-gray-200">Thank you!</h1>
            <div className="mb-10 text-blue-900 text-lg text-center">
              We're calling you in a few moments from:<br/><br/> 
              <h2>866-884-5021</h2>
              Please answer our call to
              expedite your quote request.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container mx-auto mt-12 px-4 font-sans">
      <h2 className="mb-10 text-center text-blue-800">
        Have questions about student loans?
      </h2>
      <div css={imageChartCSS}>
        <div className="column text-center">
          <a href="https://www.docupop.com">
            <img src={website} />
            <h4>Visit our website</h4>
          </a>
        </div>
        <h4 style={{ color: '#517bfe' }}>OR</h4>
        <div className="column text-center">
          <img src={call_rep} style={{ width: 'calc(125px - 5%)' }} />
          <h4>Give us a call</h4>
          <a
            href="tel:+18559106986"
            className="text-gray-800 text-xl font-bold no-underline"
          >
            <span style={{ color: '#517bfe', fontWeight: 500, fontSize: 16 }}>
              (855) 910-6986
            </span>
          </a>
        </div>
      </div>
    </div>
    <div className="font-sans text-sm text-gray-600 bg-gray-800 mt-64">
      <div className="container mx-auto py-20">
        <p>
          Docupop is a private company and is NOT AFFILIATED WITH AND DOES NOT
          HAVE ANY SPECIAL RELATIONSHIP WITH THE DEPARTMENT OF EDUCATION, loan
          servicers or any other academic or governmental entity. You can apply
          for loan consolidation or other repayment options through the
          Department of Education (DOE) at no cost, but you are instead choosing
          to use Docupop services to assist you prepare and process the
          consolidation and repayment option application paperwork for programs
          offered by the DOE. Docupop does not and will not make any payments
          for or on behalf of Student.
        </p>
        <p>
          &copy;{`${new Date().getFullYear()}`} Docupop. All rights reserved.
        </p>
      </div>
    </div>
  </ErrorBoundary>
);

export default ThankYouPage;
